import { Check } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, createTheme } from "@mui/material";
import { useEffect, useState } from "react";

export default function AcceptTerms() {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAccept = () => {

        const date = new Date();

        localStorage.setItem('pixellb_terms_accepted', JSON.stringify(date));

        handleClose();
    };

    useEffect(() => {
        const isTermsAccepted = JSON.parse(localStorage.getItem('pixellb_terms_accepted'));
        if (!isTermsAccepted) {
            handleOpen();
        };
    }, []);

    const styles = createTheme({
        dialogTitle: {
            display: 'flex',
            alignItems: 'center',
        }
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" color={'primary'} sx={styles.dialogTitle}>
                Termos de Uso e Políticas de Privacidade
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={styles.dialogContentText}>
                    Ao acessar este website, você concorda com nossos Termos de Uso e Políticas de Privacidade
                </DialogContentText>
            </DialogContent>
            <DialogActions align>
                <Button startIcon={<Check />} variant="contained" color="secondary" onClick={handleAccept}>
                    Concordo
                </Button>
            </DialogActions>
        </Dialog>
    );
};