export const UpgradesContent = [
    {
        id: 1,
        title: 'Copywriting'
    },
    {
        id: 2,
        title: 'Google Meu Negócio'
    },
    {
        id: 3,
        title: 'Dashboard de controle'
    },
    {
        id: 4,
        title: 'Facebook, Insta e Google Ads'
    }
]